
/* ============== Form section ====== */
.booking-section {
    position: relative;
    padding: 110px 0 107px;
    margin-top: 100px;
  }
  .booking-section:before {
    content: "";
    position: absolute;
    bottom: 0;
    right: 0;
    width: 100%;
    max-width: 1200px;
    height: 100%;
    background-image: linear-gradient(90deg,rgba(0,0,0,.5),rgba(0,0,0,.5)),url("../../Img/gallery/img7.jpg");
    background-repeat: no-repeat;
    background-size: 100% 100%;
    background-attachment: fixed;
  }
  
  .booking-column .booking-left {
    padding: 30px;
    position: relative;
    z-index: 9;
    margin-top: -170px;
    border-top: 10px solid var(---primaryColor);
  }
  
  .booking-column .booking-left:before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    background-color: #f8f5f0;
    z-index: 9;
    height: 100%;
  }
  .booking-column .booking-left .booking-left-Txt {
    margin-bottom: 54px;
    position: relative;
    z-index: 9;
  }
  
  .booking-left-Txt span{
    color: var(---primaryColor);
    font-family: var(---HeadFont);
    color: #b19777;
    font-weight: 300;
    font-size: 17px;
    text-transform: uppercase;
    letter-spacing: 4px;
  }
  
  .booking-left-Txt h2 {
    color: var(---darkColor);
    font-family: var(---HeadFont);
    font-weight: 500;
    font-size: 40px;
    letter-spacing: 2px;
    color: black;
    font-family: 'Playfair Display', serif;
  }
  
  .booking-column .booking-left .booking-form {
    position: relative;
    z-index: 9;
  }
  .booking-column .booking-left .booking-form label {
    color: #111;
    font-size: 14px;
    font-family: var(---HeadFont);
    width: 100%;
  }
  .booking-column .booking-left .booking-form .form-control {
    border-radius: 0px;
  }
  .booking-column .booking-left .booking-form .error-text {
    font-family: var(---HeadFont);
    font-size: 12px;
  }
  .booking-column .booking-left .booking-form button{
    font-size: 20px;
    color: white;
    background-color: black;
    padding: 5px 12px;
    border: none;
    color: white;
    padding: 7px 20px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 16px;
    border-radius: 10px;
    margin: 20px 0px 0px 0px;
    font-family: var(---HeadFont);
  }
  
  
  .booking-content .booking-right {
    padding-left: 10px;
    position: relative;
    text-align: left;
  }
  .booking-content .booking-right .bookingsec-title span {
    color: #b19777;
    font-weight: 500;
    font-size: 16px;
    letter-spacing: 0.15em;
    margin-bottom: 12px;
    text-transform: uppercase;
    font-family: var(---SubHeading);
  }
  .booking-content .booking-right .bookingsec-title h2 {
    margin-bottom: 30px;
    color:white;
    font-size: 30px;
    font-family: var(---HeadFont);
    font-family: 'Playfair Display', serif;
  }
  
  .booking-content .booking-right h4{
    color:#b19777;
    font-family: var(---SubHeading);
    font-family: 'Playfair Display', serif;
  }
  
  .booking-content .booking-right p {
    color: white;
    font-size: 16px;
    font-family: var(---SubHeading);
  }
  
  .booking-content .booking-right p a{
    color: var(---lightColor);
    font-size: 16px;
    font-family: var(---SubHeading);
    text-decoration: none;
  }
  
  @media (max-width: 992px) {
    .booking-section {
      padding: 30px 0px;
      margin-top: 50px;
    }
    .booking-column .booking-left {
      margin-top: 0px;
    }
    .contactsec-title {
      position: relative;
      margin: 10px 0px 10px 0px;
    }
    .booking-content .booking-right .bookingsec-title {
     margin-top:30px;
    }
  }
  
.Home-Section01 {
    position: relative;
    height: 100vh;
    background: #DDE2D1;
    background-image: linear-gradient(to right, rgba(15, 15, 15, 0.5), rgba(24, 23, 23, 0.5)), url("../Img/IMG_8970.jpg");
    background-size: cover;
    background-position: center, center;
}

/* 
.Home-Section01:before {
    content: '';
    position: absolute;
    width: 25%;
    height: 100%;
    background: #DDE2D1;
    top: 0;
    left: 0;
    z-index: -10;
} */

.Home-Section-Banner {
    /* border: 1px solid blue; */
    height: 85vh;
    z-index: 10000;
    display: flex;
    justify-content: center;
    align-items: center;
}

.Home-Section-Banner .swiper {
    height: 85vh;
}

.Home-Page-Banner-Slide-First {
    /* border: 1px solid red; */
    height: 100%;
}

.Home-Section-Banner-Swiper {
    /* border: 1px solid red; */
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.Home-Section-Banner-Swiper-con {
    /* border: 1px solid salmon; */
    width: 100%;
}

.Home-Section-Banner-Swiper-Image-con {
    /* border: 1px solid hotpink; */
    height: 100%;
    display: flex;
    justify-content: end;
    align-items: center;
}

.Home-Section-Banner-Swiper-Image {
    /* border: 1px solid red; */
    width: 70%;
    height: auto;
}

.Home-Section-Banner-Swiper-Image img {
    height: 100%;
    width: 100%;
    max-height: 350px;
}

.Home-Section-Banner-Swiper-text-con01 {
    height: 100%;
    display: flex;
    align-items: center;
    padding: 20px 10px 20px 40px;
    /* background: url("../Img/banner/BannerImage01.jpg"); */
    background-size: 100% 100%;
}

.Home-Section-Banner-Swiper-text-con02 {
    height: 100%;
    display: flex;
    align-items: center;
    padding: 20px 10px 20px 40px;
    /* background: url("../Img/banner/BannerImage02.jpg"); */
    background-size: 100% 100%;
}

.Home-Section-Banner-Swiper-text-con03 {
    height: 100%;
    display: flex;
    align-items: center;
    padding: 20px 10px 20px 40px;
    /* background: url("../Img/banner/BannerImage03.jpg"); */
    background-size: 100% 100%;
}

.Home-Section-Banner-Swiper-text {
    /* border: 1px solid green; */
    width: 70%;
    text-align: left;
}

.Home-Section-Banner-Swiper-text h2 {
    font-family: var(---Font02);
    font-size: 50px;
    color: #fff;
}

.Home-Section-Banner-Swiper-text h1 {
    font-family: var(---Font02);
    font-size: 50px;
    color: #fff;
}

.Home-Section-Banner-Swiper-text p {
    color: #eee;
    font-size: 16px;
}

/*  ============ About Section =========== */

.about {
    position: relative;
    padding: 50px 0;
}

.about .exp-img {
    /* border: 1px solid red; */
}

.years {
    background: rgba(255, 255, 255, 0.95);
    /* border: 1px solid blue; */
}

.about .exp-img .img {
    width: 90%;
    height: 500px;
    position: relative;
    background-image: url("../Img/Work\ Images/bedroom wall design.webp ");
    z-index: 2;
}

.imago.animated {
    -webkit-clip-path: polygon(0 0, 100% 0, 100% 100%, 0 100%);
    clip-path: polygon(0 0, 100% 0, 100% 100%, 0 100%);
}

.imago {
    -webkit-clip-path: polygon(0 0, 100% 0, 100% 0, 0 0);
    clip-path: polygon(0 0, 100% 0, 100% 0, 0 0);
    -webkit-transition: all .8s;
    -o-transition: all .8s;
    transition: all .8s;
    -webkit-transition-delay: .3s;
    -o-transition-delay: .3s;
    transition-delay: .3s;
    margin-top: 20px;
}

.bg-img {
    background-size: cover;
    background-repeat: no-repeat;
}

.about .exp-img .img .since {
    /* position: absolute;
    right: 50px;
    top: 64%; */
}

.about .exp-img .img .years {
    position: absolute;
    left: 55%;
    bottom: 30px;
    text-align: left;
    padding: 15px 10px;
}

.about .exp-img .img {
    width: 90%;
    height: 500px;
    position: relative;
    z-index: 2;
}

.about .exp-img .img .since span:first-of-type {
    font-size: 16px;
    font-weight: 600;
    letter-spacing: 2px;
}

.about .exp-img .img .since span {
    display: block;
    color: #b19777;
    font-size: 22px;
    font-weight: 700;
    text-transform: uppercase;
    line-height: 1;
}

.about .exp-img .img .years h2 {
    font-size: 60px;
    font-weight: 800;
    background: url("../Img/about-img.webp") no-repeat;
    background-position: 18% 65%;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    line-height: 1;
}

/* .about .exp-img .img:after {
    content: '';
    position: absolute;
    top: 60%;
    left: 50%;
    right: 20px;
    bottom: 20px;
    background: rgba(255, 255, 255, 0.95);
    z-index: -1;
} */

.about .exp-img .img .years h5 {
    color: #b19777;
    /* letter-spacing: 5px; */
    font-size: 20px;
}

/* side section  */
.about .exp-content {
    position: relative;
    z-index: 2;
    text-align: left;
}

.sub-title {
    color: #b19777;
    font-family: 'Teko', sans-serif;
    text-transform: uppercase;
    font-weight: 400;
    font-size: 14px;
    padding-left: 60px;
    letter-spacing: 4px;
    margin-bottom: 15px;
    position: relative;
}

.sub-title:before {
    content: "";
    width: 50px;
    height: 1px;
    background: #abaaaa;
    position: absolute;
    left: 0;
    top: 50%;
}

.playfont {
    font-family: 'Playfair Display', serif;
}

p {
    color: #888;
    font-size: 15px;
    font-weight: 400;
    line-height: 2;
    margin: 0;
}

.about .exp-content:after {
    content: '';
    width: 200px;
    height: 250px;
    position: absolute;
    top: -40px;
    right: -40px;
    border: 10px solid #fafafa;
    z-index: -1;
}

.numbers .item {
    position: relative;
}

.numbers .item:before,
.numbers .item:after {
    content: '';
    position: absolute;
    right: 20%;
    z-index: -1;
}

.numbers .item:before {
    width: 0;
    height: 0;
    border-top: 35px solid transparent;
    border-right: 35px solid #f7f7f7;
    bottom: 35px;
}

.numbers .item h3 {
    margin-bottom: 10px;
}

.numbers .item h3 span {
    font-size: 60px;
    color: #b19777;
}

.custom-font {
    font-family: 'Teko', sans-serif;
    font-weight: 400;
}

.numbers .item h6 {
    font-size: 12px;
    font-weight: 500;
    text-transform: uppercase;
    letter-spacing: 2px;
}

.numbers .item:after {
    width: 35px;
    height: 35px;
    bottom: 0;
    background: #f7f7f7;
}

.mb-20 {
    margin-bottom: 20px !important;
}

.mt-50 {
    margin-top: 50px !important;
}


.valign {
    display: flex;
    justify-content: center;
    align-items: center;
}

@media(max-width:990px) {
    .numbers .item h3 span {
        font-size: 30px;
        color: #b19777;
    }
}


/* --------------------- Section 3 -------------------- */


.bg-gray {
    background-color: #f8f4f3;
}

.section-head {
    position: relative;
    margin: 0 auto 30px;
}

.section-head h6 {
    color: #b19777;
    font-weight: 300;
    font-size: 17px;
    text-transform: uppercase;
    letter-spacing: 4px;
    margin-bottom: 10px;
}

.section-head h4 {
    font-weight: 500;
    font-size: 40px;
    letter-spacing: 2px;
    color: black;
    font-family: 'Playfair Display', serif;
}

.playfont {
    font-family: 'Playfair Display', serif;
}

.services .bord-box {
    overflow: hidden;
}

.bg-img {
    background-size: cover;
    background-repeat: no-repeat;
}

.Servicecol {
    margin: 20px 0px;
}

.services .item-bx {
    padding: 10px 20px;
    height: 100%;
    background-color: #fff;
    border: 1px solid #eee;
    border-right: 0;
    -webkit-transition: all .4s;
    -o-transition: all .4s;
    transition: all .4s;
    text-align: left;
    transition: all .5s ease-in-out;
}

.services .item-bx p {
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 3;
    display: -webkit-box;
    font-size: 14px;
    overflow: hidden;
}

.services .item-bx .numb {
    font-size: 60px;
    margin-bottom: 10px;
    color: transparent;
    -webkit-text-stroke: 1px #b19777;
}

.custom-font {
    font-family: 'Teko', sans-serif;
    font-weight: 400;
}

.services .item-bx .more {
    text-decoration: none;
    font-size: 14px;
    font-family: 'Teko', sans-serif;
    color: #b19777;
}

.mt-30 {
    margin-top: 30px !important;
}

.custom-font {
    font-family: 'Teko', sans-serif;
    font-weight: 400;
}

.services .item-bx:hover {
    background-color: rgba(0, 0, 0, 0.5);
    color: #fff;
    transition: all .5s ease-in-out;
}

.services .item-bx:hover p {
    color: white !important;
}

.services .item-bx h6 {
    font-size: 17px;
    font-family: 'Playfair Display', serif;
}

.hsIthree {
    /* background-image: url("../Img/FB_IMG_1647428611254.jpg"); */
    transition: all .5s ease-in-out;
    display: flex;
    justify-content: center;
    align-items: center;
}



/* ----------------- Section 4 ----------------------- */

.skills-circle {
    background-image: linear-gradient(90deg, rgba(0, 0, 0, .5), rgba(0, 0, 0, .5)), url("../Img/gallery/img7.jpg");
    background-size: cover;
    background-repeat: no-repeat;
    background-attachment: fixed;
    background-position: center 48.4083px;
}



.skills-circle .skill {
    /* position: relative; */
}

.skills-circle .skill span {
    width: 150px !important;
    height: 150px !important;
    line-height: 150px;
    border-radius: 100%;
    background: rgba(255, 255, 255, 0.1);
    /* position: absolute; */
    font-size: 35px;
    font-weight: 300;
    color: #fff;
    padding: 40px 25px;
}

.skills-circle .skill h6 {
    font-size: 14px;
    text-transform: uppercase;
    letter-spacing: 2px;
    color: #fff;
    text-align: center;
}

@media(max-width:990px) {
    .skills-circle .skill h6 {
        margin-top: 0px;
    }
}

/* ------------------------------ Section 5 ---------------------------- */
.Home-Section-5-Testimonial {
    padding: 20px 0px;
}

.testimonials.grid .item {
    position: relative;
    margin: 10px 20px;
}

.slick-initialized .slick-slide {
    display: block;
}

.slick-slide {
    /* display: none; */
    float: left;
    height: 100%;
    min-height: 1px;
}

.testimonials.grid .item .quote-icon {
    position: absolute;
    top: -15px;
    right: 12%;
    width: 10%;
    z-index: 2;
}

.slick-slide img {
    display: block;
    width: 100%;
    height: auto;
}

.testimonials.grid .item .cont {
    padding: 50px 10px 40px 30px;
    border: 10px solid #f7f7f7;
    border-top: 0;
    position: relative;
    min-height: 100%;
}

.testimonials.grid .item .cont:before {
    width: 5%;
    right: 0;
}

.testimonials.grid .item .cont:after,
.testimonials.grid .item .cont:before {
    content: '';
    position: absolute;
    height: 10px;
    background: #f7f7f7;
    top: 0;
}

.testimonials.grid .item .cont p {
    font-size: 16px;
    font-weight: 400;
    font-style: italic;
    color: #333;
    overflow-y: scroll;
    height: 25vh;
    padding: 0px 20px 0px 0px;
}

.playfont {
    font-family: 'Playfair Display', serif;
}

.testimonials.grid .item .cont:after {
    width: 75%;
    left: 0;
}

.testimonials.grid .item .cont:after,
.testimonials.grid .item .cont:before {
    content: '';
    position: absolute;
    height: 10px;
    background: #f7f7f7;
    top: 0;
}

.testimonials.grid .item .info {
    margin-top: 30px;
    padding-left: 30px;
    position: relative;
}

.testimonials.grid .item .info .author {
    width: 70px;
    height: 70px;
    border-radius: 50%;
    overflow: hidden;
    display: table-cell;
}

.testimonials.grid .item .info .author img {
    width: 100%;
    height: 100%;
    -o-object-fit: cover;
    object-fit: cover;
    -o-object-position: center;
    object-position: center;
}

.testimonials.grid .item .info h6 {
    display: table-cell;
    vertical-align: middle;
    padding-left: 20px;
    font-size: 16px;
}

.testimonials.grid .item .info h6 span {
    color: #b19777;
    font-size: 13px;
    font-weight: 400;
    margin-top: 5px;
    display: block;
}

.testimonials.grid .item .info:after {
    content: '';
    width: 0;
    height: 0;
    border-top: 25px solid #f7f7f7;
    border-right: 30px solid transparent;
    position: absolute;
    left: 100px;
    top: -30px;
}

@media(max-width:1024px) {
    .slick-slide {
        width: auto !important;
    }

    .fotcont {
        margin-bottom: 20px;
    }
}

@media(max-width:992px) {
    .Home-Section-Banner-Swiper-text h2 {
        font-size: 30px;
    }

    .Home-Section-Banner-Swiper-text h1 {
        font-size: 30px;
    }

    .Home-Section-Banner-Swiper-text {
        /* border: 1px solid green; */
        width: 100%;
        text-align: left;
    }

    .section-head {
        position: relative;
        margin: 0 auto 30px;
    }
}

@media(max-width:768px) {
    .Home-Section-Banner-Swiper-Image-con {
        /* display: none; */
    }

    .Home-Section-Banner-Swiper-Image-con {
        /* border: 1px solid hotpink; */
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .Home-Section-Banner-Swiper-Image {
        /* border: 1px solid red; */
        width: 100%;
        height: auto;
        margin: 20px 0px 20px 0px;
    }

    .Home-Section-Banner-Swiper-text-con01 {
        margin: 20px 0px 20px 0px;
    }

    .about .exp-img .img {
        width: 70%;
        height: 370px;
        position: relative;
        z-index: 2;
    }

    .about .exp-content {
        position: relative;
        z-index: 2;
        text-align: left;
        margin: 20px 0px;
    }
}

@media(max-width:576px) {
    .Home-Section01 {
        height: 50vh !important;
    }
    .Home-Section-Banner .swiper {
        height: 40vh;
    }

    .Home-Section-Banner {
        height: 50vh;
    }


    .Home-Section-Banner-Swiper-text h2 {
        font-size: 20px !important;
    }

    .Home-Section-Banner-Swiper-text h1 {
        font-size: 20px !important;
    }

    .Home-Section-Banner-Swiper {
        height: auto;
    }

    .Home-Section-Banner-Swiper-text p {
        color: #eee;
        font-size: 13px;
    }

    .Home-Section-Banner-Swiper-text-con01 {
        margin: 0px;
    }
}

@media(max-width:430px) {
    .about .exp-img .img {
        width: 100%;
    }
}

/*** 
=============================================
    Brand Area Style      
=============================================
***/
.brand-area {
    position: relative;
    display: block;

    padding-top: 110px;
    padding-bottom: 90px;
}

.brand-items {
    position: relative;
    display: block;
}

.single-brand-item {
    position: relative;
    display: block;
}

.single-brand-item a {
    display: block;
    background: #ffffff;
    text-align: center;
    transition: all 700ms ease 200ms;
}

.single-brand-item a:hover {
    -webkit-box-shadow: 0px 5px 10px 2px #ebebeb;
    box-shadow: 0px 5px 10px 2px #ebebeb;
}

.single-brand-item a img {
    width: auto;
    height: 150px;
    transition: all 900ms ease 200ms;
    /* transform: perspective(1200px) rotateY(0deg) translateZ(0px); */
}

/* .single-brand-item:hover a img{
    filter: brightness(0%); 
    transform: perspective(1200px) rotateY(360deg) translateZ(0px);
} */
.single-brand-item .overlay-content {
    position: absolute;
    left: 0px;
    right: 0;
    bottom: -35px;
    width: 130px;
    margin: 0 auto;
    opacity: 0;
    visibility: hidden;
    padding: 3px 0px 2px;
    text-align: center;
    background-color: #e9212e;
    transition: all 300ms ease 100ms;
    z-index: 999;
}

.single-brand-item:hover .overlay-content {
    bottom: -15px;
    visibility: visible;
    opacity: 1;
}

.single-brand-item .overlay-content:before {
    font-family: FontAwesome;
    content: "\f0de";
    position: absolute;
    top: -8px;
    left: 0;
    right: 0;
    text-align: center;
    color: #e9212e;
    font-size: 30px;
}

.single-brand-item .overlay-content p {
    color: #ffffff;
    font-size: 16px;
    font-weight: 600;
    font-family: 'Poppins', sans-serif;
    margin: 0;
}

.brand-items-carousel.owl-carousel .owl-stage-outer {
    position: relative;
    overflow: hidden;
    padding-bottom: 20px;
}

.brand-items-carousel.owl-carousel .owl-nav {
    position: absolute;
    right: 0;
    top: -115px;
    margin: 0;
    display: block;
}

.brand-items-carousel.owl-carousel .owl-nav [class*="owl-"] {
    position: relative;
    display: inline-block;
    background: #f6f6f6;
    width: 55px;
    height: 55px;
    border: 1px solid #dddddd;
    line-height: 54px;
    margin: 0;
    padding: 0;
    transition: all 700ms ease 100ms;
    border-radius: 50%;
}

.brand-items-carousel.owl-carousel .owl-nav [class*="owl-"] i:before {
    color: #ababab;
    font-size: 20px;
    font-weight: 400;
}

.brand-items-carousel.owl-carousel .owl-nav .owl-next {
    margin-left: 10px;
}

.brand-items-carousel.owl-carousel .owl-nav [class*="owl-"]:hover {
    border-color: #e9212e;
}
.AboutUs-Section-1 {
    height: 80vh;
    background: linear-gradient(90deg, rgba(0, 0, 0, .3), rgba(0, 0, 0, .4)), url("../Img/gallery/IMG20171119152948.jpg");
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
}

.about-txt {
    text-align: left;
}

.AboutUs-Section-Banner {
    height: 80vh;
    display: flex;
    justify-content: center;
    align-items: center;
}

.Aboutus-banner-text h1 {
    font-family: 'Playfair Display', serif;
    font-size: 40px;
    font-weight: 600;
    margin: 0px 0px 20px 0px;
    letter-spacing: 2px;
    color: white;
}

.Aboutus-banner-text-page {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
}


.Aboutus-banner-text-page h2 {
    font-size: 17px;
    color: #b19777;
    margin: 0px;
}

.Aboutus-banner-text-page a {
    text-decoration: none;
    font-size: 17px;
    color: #b19777;
}


/* =========== About Section ======== */
.about-section {
    padding: 70px 0px;
}

.aboutImg .home-about {
    position: relative;
    padding-left: 20px;
    padding-bottom: 82px;
    width: 80%;
}

.aboutImg .img1 {
    position: relative;
    display: inline-block;
    border-radius: 10px;
    margin-bottom: 0px;
    width: 100%;
    height: 400px;
}

.aboutImg .img1 img {
    width: 100%;
    height: 100%;
    border-radius: 7px;
    box-shadow: 0 5px 7px gray;
}

.home-about .img2 {
    position: absolute;
    right: 0px;
    bottom: 41px;
    margin-bottom: 0;
    border: 10px solid #ffff;
    overflow: hidden;
    width: 200px;
    height: 225px;
    border-radius: 7px;
}

.aboutImg .img2 img {
    width: 100%;
    height: 100%;
}

.about-txt span {
    font-family: var(---Font02);
}

.about-txt h2 {
    font-family: var(---Font02);
}

.about-txt p {
    text-align: left;
}

.text button {
    border: none;
    padding: 5px 15px;
    font-size: 12px;
    border-radius: 30px;
    margin: 0px 0px 0px 10px;
    color: #e9c37a;
    background: black;
}


@media all and (max-width: 450px) {
    .aboutImg .img1 {
        width: 100%;
        height: 100%;
    }

    .aboutImg .img2 {
        position: absolute;
        /* right: -12px; */
        bottom: 41px;
        margin-bottom: 0;
        border: 10px solid #fff;
        overflow: hidden;
        width: 150px;
        height: 175px;
    }
}

@media all and (max-width: 310px) {
    .aboutImg .img2 {
        display: none;
    }
}



/* ---------------------------------- Section 2 -------------------------------- */

.about {
    position: relative;
}


.txt-cont {
    text-align: left;
}

.numbers .item h3 {
    margin-bottom: 10px;
}

.numbers .item h3 span {
    font-size: 60px;
    color: #b19777;
}

.numbers .item h6 {
    font-size: 12px;
    font-weight: 500;
    text-transform: uppercase;
    letter-spacing: 2px;
}

.mb-50 {
    margin-bottom: 50px !important;
}

@media(max-width:990px) {
    .numbers .item {
        margin-bottom: 20px;
    }

    .numbers .item h3 span {
        font-size: 30px;
        color: #b19777;
    }
}

/* ------------------------- Section 3 -------------------------- */

.branded-section.style-two {
    border-bottom: 0px;
}

.branded-section {
    background: url("../Img/Work\ Images/false ceiling 2.webp");
    position: relative;
    background-size: cover;
    padding: 90px 0px 100px;
    background-repeat: no-repeat;
    background-position: center bottom;
    border-bottom: 1px solid #eeeeee;
}

.branded-section:before {
    position: absolute;
    content: '';
    left: 0px;
    top: 0px;
    right: 0px;
    bottom: 0px;
    height: auto;
    background-color: rgba(48, 48, 48, 0.90);
}

.auto-container {
    position: static;
    max-width: 1200px;
    padding: 0px 15px;
    margin: 0 auto;
}

.branded-section .title-box {
    position: relative;
    margin-bottom: 50px;
}

.sec-title {
    position: relative;
    margin-bottom: 30px;
}

.pull-left {
    float: left;
    text-align: left;
}

.branded-section .title-box .sec-title .title {
    color: #ffffff;
}

.sec-title .title {
    color: #b19777;
    font-family: 'Teko', sans-serif;
    text-transform: uppercase;
    font-weight: 400;
    font-size: 14px;
    padding-left: 6px;
    letter-spacing: 4px;
    margin-bottom: 15px;
}

.sec-title.light h2,
.sec-title.light .text {
    color: #ffffff;
}

.sec-title h2 {
    position: relative;
    color: #222222;
    font-weight: 700;
    line-height: 1.3em;
    font-size: 34px;
    display: inline-block;
    text-transform: capitalize;
    font-family: var(---Font02);
}

.sec-title h2:before {
    position: absolute;
    content: '';
    left: -65px;
    top: 22px;
    height: 2px;
    width: 50px;
    background-color: #e9c37a;
}

.pull-right {
    float: left;
}

.branded-section .title-box .sec-title .text {
    margin-top: 38px !important;
    text-align: center;
    font-size: 15px;
    font-weight: 400;
    line-height: 2;
    margin: 0;
}

.sec-title.light h2,
.sec-title.light .text {
    color: #ffffff;
}

.sec-title .text {
    position: relative;
    color: #666666;
    font-weight: 500;
    line-height: 1.8em;
    font-size: 14px;
    margin-top: 15px !important;
}

.clearfix::after {
    display: block;
    clear: both;
    content: "";
}

.branded-section .lower-section {
    position: relative;
}

.branded-section .lower-section .row {
    margin: 0px -55px;
}

/* 
section .lower-section .brand-block-two {
    padding: 0px 55px;
} */

.brand-block-two {
    position: relative;
    z-index: 1;
    margin-bottom: 20px;
}

.brand-block-two .inner-box {
    position: relative;
    background-color: #ffffff;
    box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.10);
}

.brand-block-two .inner-box .upper-box {
    position: relative;
}

.brand-block-two .inner-box .upper-box .upper-inner {
    position: relative;
    /* padding-left: 60px; */
    padding-bottom: 20px;
    background-color: #e9c37a;
}

.brand-block-two .inner-box .upper-box .upper-inner .image {
    position: relative;
    margin-top: -35px;
    display: inline-block;
    width: 100%;
    height: 100%;
}

.brand-block-two .inner-box .upper-box .upper-inner .image img {
    display: inline-block;
    width: 100%;
    height: 100%;
    max-width: 250px;
    max-height: 250px;
}

.brand-block-two .inner-box .lower-box {
    position: relative;
}

.brand-block-two .inner-box .lower-box .box-inner {
    position: relative;
    padding: 50px 55px;
    text-align: left;
}

.brand-block-two .inner-box .lower-box .box-inner h3 {
    position: relative;
    color: #222222;
    font-size: 24px;
    font-weight: 500;
    line-height: 1.4em;
    padding-bottom: 20px;
    font-family: 'Playfair Display', serif;
}

.brand-block-two .inner-box .lower-box .box-inner h3:before {
    position: absolute;
    content: '';
    left: 0px;
    height: 3px;
    width: 50px;
    bottom: 0px;
    background-color: #222222;
}

.brand-block-two .inner-box .lower-box .box-inner .text {
    color: #888;
    font-size: 15px;
    font-weight: 400;
    line-height: 2;
    margin: 0;
}

.clearfix::after {
    display: block;
    clear: both;
    content: "";
}

.branded-section:after {
    position: absolute;
    content: '';
    left: 0px;
    bottom: 0px;
    width: 100%;
    height: 215px;
    background-color: #ffffff;
}

/* ///////////////////////////// Section 2 ////////////////////////////// */

.Home-Section-2 {
    background: linear-gradient(to right, rgba(15, 15, 15, 0.7), rgba(24, 23, 23, 0.7)), url('../Img/IMG_8970.jpg');
    padding: 10vh 0;
    background-attachment: fixed;
}

.Home-Section-1-Con {
    display: flex;
    justify-content: center;
    align-items: center;
}

.HomeSection2-Whyus-text {
    /* border: 1px solid blue; */
    display: flex;
    justify-content: center;
    align-items: center;
}



.HomeSection2-Whyus-maintext h2 {
    font-size: 50px;
    letter-spacing: 2px;
    text-transform: uppercase;
    font-family: var(---Font02);
    color: #fff;
}


.HomeSection2-Whyus-subtext-con {
    display: flex;
    justify-content: center;
    align-items: center;
}

.HomeSection2-Whyus-subtext {
    text-align: left;
    margin: 1px 0px 0px 10px;
}

.HomeSection2-Whyus-subtext h3 {
    margin: 0px;
    padding: 0px;
    font-size: 22px;
    letter-spacing: 1px;
    text-transform: uppercase;
    font-family: var(---Font03);
    color: #b19777;
}

.HomeSection2-Whyus-subtext h4 {
    text-transform: uppercase;
    letter-spacing: 1px;
    font-size: 18px;
    font-family: var(---Font03);
    font-weight: 600;
    color: #b19777;
}

.HomeSection2-Whyus-subtext-Q {
    /* border: 1px solid blue; */
    margin: 0px 0px 0px 5px;
}

.HomeSection2-Whyus-subtext-Q h2 {
    font-size: 50px;
    font-family: var(---StyleFont);
    color: #b19777;
}

/* ///// */

.Home-Section-WhyUS-Icon-Con {
    /* border: 1px solid blue; */
    margin: 40px 0px;
}

.demo {
    display: flex;
    justify-content: center;
    align-items: center;
}



.Home-Section-WhyUS-Icon-Image-con {
    /* border: 1px solid saddlebrown; */
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 20px 0px;
}

.Home-Section-WhyUS-Icon-Image {
    /* border: 1px solid hotpink; */
    background-color: #fff;
    height: 15vh;
    width: 15vh;
    box-shadow: 0px 0px 5px rgb(164, 163, 163);
    border-radius: 65px;
    padding: 20px;
}

.Home-Section-WhyUS-Icon-Image img {
    height: 100%;
    width: 100%;
}

.Home-Section-WhyUS-Icon h6 {
    /* border: 1px solid red; */
    font-size: 12px;
    font-weight: 500;
    text-transform: uppercase;
    letter-spacing: 1px;
    color: #fff;
}

@media(max-width:430px) {
    .HomeSection2-Whyus-maintext h2 {
        font-size: 35px;
        letter-spacing: 1px;
    }

    .HomeSection2-Whyus-subtext h3 {
        font-size: 17px;
    }

    .HomeSection2-Whyus-subtext h4 {
        font-size: 16px;
    }

    .Home_Section4_1_swiper_con .swiper-wrapper {
        height: 50vh;
    }

    .AboutUs-Section-1 {
        /* border: 1px solid red; */
        height: 50vh;
        background-size: 100% 100%;
    }

    .Aboutus-banner-text h1 {
        font-family: 'Playfair Display', serif;
        font-size: 26px;
        font-weight: 600;
        margin: 0px 0px 0px 0px;
        letter-spacing: 2px;
        color: white;
    }

    .AboutUs-Section-Banner {
        height: 35vh;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .section-head h6 {
        color: #b19777;
        font-weight: 300;
        font-size: 14px;
    }

    .section-head h4 {
        font-weight: 500;
        font-size: 25px;
    }

    .Home-Section-2 {
        padding: 7vh 0;
    }
    .sec-title h2 {
        font-size: 26px;
    }

}

@media(max-width:992px) {
    .txt-cont {
        text-align: left;
        margin: 20px 0px 0px 0px;
    }
}

@media(max-width:768px) {
    .section-padding {
        padding: 70px 0 70px 0px;
    }

    .brand-block-two .inner-box .lower-box .box-inner {
        position: relative;
        padding: 35px 25px;
        text-align: left;
    }

    .brand-block-two .inner-box .upper-box .upper-inner {
        padding-left: 0px;
    }

    .about .exp-img .img .since {
        margin-bottom: 10px;
    }

}

@media(max-width:576px) {
    .Home-Section-WhyUS-Icon-Image {
        background-color: whitesmoke;
        height: 12vh;
        width: 12vh;
        box-shadow: 0px 0px 5px grey;
        border-radius: 65px;
        padding: 10px;
    }
}

/* //////////////////////////////////////////////// */
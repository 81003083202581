/* ============ Banner Section =========== */
.contact-Section-1 {
    height: 80vh;
    background: linear-gradient(90deg, rgba(0, 0, 0, .3), rgba(0, 0, 0, .4)), url("../Img/gallery//Contact.jpg");
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
}

.contact-Section-Banner{
    height: 80vh;
    display: flex;
    justify-content: center;
    align-items: center;
}

.contact-banner-text h1 {
    font-family: 'Playfair Display', serif;
    font-size: 40px;
    font-weight: 600;
    margin: 0px 0px 20px 0px;
    letter-spacing: 2px;
    color: white;
}

.contact-banner-text-page {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
}


.contact-banner-text-page h2 {
    font-size: 17px;
    color: #b19777;
    margin: 0px;
}

.contact-banner-text-page a {
    text-decoration: none;
    font-size: 17px;
    color: #b19777;
}
/* ------------------ Section 2 --------------------- */
.Contact-Section-2 {
    /* border: 1px solid red; */
    padding: 10vh 0px;
    background-color: #f8f4f3;
}

.Contact-Section-Contact-Details-Con {
    /* border: 1px solid blue; */
}

.Contact-Section-Card {
    margin: 20px 0px;
    display: flex;
    padding: 25px;
    background-color: white;
    height: 100%;
}

.Contact-Section-Card-icon-con {
    /* border: 1px solid black; */
    margin: 0px 20px 0px 0px;
    /* height: 100%; */
}

.Contact-Section-Card-icon {
    /* border: 1px solid darkblue; */
}

.Contact-Section-Card-icon svg {
    font-size: 40px;
    color: #b19777;
}

.Contact-Section-Card-Details {
    /* border: 1px solid red; */
    text-align: left;

}

.Contact-Section-Card-Details h3 {
    font-family: 'Playfair Display', serif;
    font-size: 20px;
    color: #b19777;
    margin-bottom: 5px;
}

.Contact-Section-Card-Details p {
    font-size: 13px;
    color: #888;
    font-size: 15px;
    font-weight: 400;
    line-height: 2;
    margin: 0;
}
.Contact-Section-Card-Details p a{
    text-decoration: none;
    font-size: 13px;
    color: #888;
    font-size: 15px;
    font-weight: 400;
    line-height: 2;
    margin: 0;
}


/* //////////////////////////////////////////// section 4 /////////////////////////////////////////// */

.ContactUs-form-Section-4 {
    margin-top: 10vh;
    margin-bottom: 10vh;
}

.contact-form-image {
    height: 60vh;
    box-shadow: 0 0 5px #808080;
    border-radius: 5px;
}

.form-control {
    background-color: rgb(228, 228, 228);
    height: 50px;
}

.Email {
    margin-top: 20px;
    margin-bottom: 20px;
}

.Phone-no {
    margin-top: 13px;
    margin-bottom: 20px;
}

.message textarea {
    height: 100px;
}

.form-submit-button {
    border: none;
    margin-top: 20px;
    border-radius: 5px;
    padding: 10px 20px;
    background-color: var(---Color01);
    color: white;
}


@media(max-width:767px) {
    .Name-input {
        margin-top: 20px;
    }

    .Contact-Section-Card {
        height: auto;
        margin: 0px;
    }
}

@media(max-width:430px) {

    .contact-Section-1 {
        height: 50vh;
    }

    .contact-Section-Banner{
        height: 35vh;
    }

    .contact-banner-text h1 {
        font-family: 'Playfair Display', serif;
        font-size: 26px;
        font-weight: 600;
        margin: 0px;
    }
}

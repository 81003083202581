.ReviewsSwaiper{
    padding: 10vh 0px 10vh 0px;
}

.ReviewsName {
    height: 240px;
    width: 100%;
}

.ReviewsName img {
    height: 100% !important;
    width: 100% !important;
}

.ReviewsDescription {
    margin: 10px;
    width: 100%;
    text-align: left;
}

.Reviews-Container {
    box-shadow: 0 0 5px #808080;
    border-radius: 5px;
    margin: 12px;
    padding: 20px;
}

.swiper-button-prev {
    top: 150px;
    display: none !important;
}

.swiper-button-next {
    top: 150px;
    display: none !important;
}


.Rtitle h1 {
    font-weight: 500;
    font-size: 40px;
    letter-spacing: 2px;
    color: black;
    font-family: 'Playfair Display', serif;
    padding: 0px 0px 25px 0px;
}

.ReviewsName h5 {
    color: #8d8d8d;
    padding: 7px 0;
    font-size: 1.2rem;
    font-weight: 500;
    border-radius: 5px;
    margin: 30px 0 30px 0;
    /* position: relative; */
    font-family: sans-serif;
    text-align: center;
}

.ReviewsDescription h6{
    color: gold;
    margin-left: 10px;
}

.star{
    display: flex;
}

.star h5{
    font-size: 18px;
    text-transform: capitalize;
    color: var(---SmallFont);

}

.ReviewsDescription p {
    text-align: left;
    font-size: 0.9rem;
    text-transform: capitalize;
    font-weight: 600;
    font-family: 'Nanum Myeongjo', serif;
    color: #000000c4;
    height: 16vh;
    font-size: 13px;
    overflow-y: scroll;
    padding: 10px 10px 10px 0px;
}

/*
 *  STYLE 1
 */

 #style-1::-webkit-scrollbar-track
 {
     -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
     border-radius: 10px;
     background-color: #F5F5F5;
 }
 
 #style-1::-webkit-scrollbar
 {
     width: 4px;
     background-color: #F5F5F5;
 }
 
 #style-1::-webkit-scrollbar-thumb
 {
     border-radius: 10px;
     -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,.3);
     background-color: #555;
 }
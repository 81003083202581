/* ============ Project Banner ========= */

/* ============ Banner Section =========== */
.project-Section-1 {
  height: 80vh;
  background: linear-gradient(90deg, rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.4)),
    url("../Img/gallery/img9.jpg");
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
}

.project-Section-Banner {
  height: 80vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.project-banner-text h1 {
  font-size: 40px;
  font-weight: 600;
  margin: 0px 0px 20px 0px;
  letter-spacing: 2px;
  color: white;
  font-family: var(---Font01);
  font-family: 'Playfair Display', serif;
}

.project-banner-text-page {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
}

.Magazine-Section-02-headCon h2{
  font-family: var(---Font01);

}

.project-banner-text-page h2 {
  font-size: 17px;
  color: #b19777;
  margin: 0px;
}

.project-banner-text-page a {
  text-decoration: none;
  font-size: 17px;
  color: #b19777;
}

/* ////////////////////// Section 2 //////////////////// */

.Cars-Section-2 {
  /* border: 1px solid blue; */
  padding: 0px 0px 5vh 0px;
}

.Cars-Section-Show {
  /* border: 1px solid red; */
  padding: 10px;
}

/* ///////// card /////// */

.Cars-Card-con {
  /* border: 1px solid greenyellow; */
  border-radius: 10px;
  box-shadow: 0 0 3px gray;
  margin-top: 20px;
}

.Cars-Card-Image-con {
  /* border: 1px solid red; */
  height: 45vh;
  border-top-right-radius: 10px;
  border-top-left-radius: 10px;
}

.Cars-Card-Image-con img {
  height: 100%;
  width: 100%;
  border-top-right-radius: 10px;
  border-top-left-radius: 10px;
}

.Cars-Card-Details {
  padding: 20px 20px 20px 20px;
}

.Cars-Card-Details h2 {
  text-align: center;
  font-size: 20px;
  margin: 0px;
  font-family: "Playfair Display", serif;
}

.Cars-Card-Star-con {
  display: flex;
}

.First-Star svg {
  font-size: 17px;
}

.Cars-Card-reviews-text {
  /* border: 1px solid red; */
  margin: 0px 0px 0px 7px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.Cars-Card-reviews-text h3 {
  margin: 0px;
  font-size: 12px;
}

.Last-car-Info-con {
  /* border: 1px solid blue; */
  display: flex;
  justify-content: space-between;
}

.Last-Car-Info {
  /* border: 1px solid red; */
  display: flex;
  align-items: center;
  text-align: center;
  padding: 10px 0px 0px 0px;
}

.Last-Car-Info-person {
  margin: 0px 15px 0px 0px;
}

.Last-Car-Info-icons {
  text-align: left;
}

.Last-Car-Info-icons svg {
  font-size: 20px;
}

.Last-Car-Info-icons-details {
  margin: 5px 0px 0px 0px;
}

.Last-Car-Info-icons-details h4 {
  font-size: 13px;
  font-weight: 400;
}

/* /////////// */

.Cars-Section-Details {
  display: flex;
  justify-content: center;
  align-items: center;
}

.Cars-Section-contact-Details {
  padding: 15px;
  border: 1px solid gray;
  border-radius: 5px;
  margin-top: 5vh;
}

.Cars-Section-contact-Details h4 {
  text-align: left;
  padding-bottom: 5px;
  border-bottom: 1px solid gray;
  font-size: 18px;
  font-weight: 700;
  font-family: var(---HeadFont);
}

.Cars-Section-contact-Detailsandicons {
  display: flex;
}

.cars-section-detailsicon {
}

.cars-section-detailsicon svg {
  font-size: 25px;
}

.cars-section-detailstext {
  margin: 0px 10px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.cars-section-detailstext p {
  font-size: 15px;
  color: gray;
  margin: 0px;
}

/* //////// Cars-map-con */

.Cars-map-con {
  border: 1px solid gray;
  margin-top: 5vh;
  padding: 15px;
  border-radius: 5px;
}

.Cars-map {
  border: 1px solid greenyellow;
  height: 50vh;
  border-radius: 5px;
}

/* //////////////// Social Media /////////////// */

.Cars-Social-Media-con {
  margin-top: 5vh;
  padding: 15px;
  border: 1px solid gray;
}

.Cars-Social-Media-con h4 {
  text-align: left;
  padding-bottom: 5px;
  border-bottom: 1px solid gray;
  font-size: 18px;
  font-weight: 700;
  font-family: var(---HeadFont);
}

.Cars-Social-Media-icons-con {
  /* border: 1px solid blue;   */
  display: flex;
  align-items: center;
}

.Cars-Social-Media-icons {
  /* border: 1px solid red; */
  height: 7vh;
  width: 7vh;
  border-radius: 25px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  margin-right: 10px;
}

.Cars-Social-Media-icons svg {
  font-size: 25px;
  color: white;
}

.facebook {
  background-color: #2d5f9a;
}

.twitter {
  background-color: #00c3f3;
}

.youtube {
  background-color: #cc181e;
}

.Insta {
  background-color: #d62976;
}

.whatsapp {
  background-color: #075e54;
}

/* ////////// cars Filter BTN ////////// */

.Filter-Cars-Main-Con {
  /* border: 1px solid red; */
  /* border: 1px solid gray;
    border-radius: 5px;
    padding: 15px; */
  width: 30%;
}

.Filter-Cars-Main-Con select {
  width: 100%;
  height: 7vh;
  color: var(---Color01);
  padding: 0px 20px 0px 20px;
  border: 1px solid var(---Color01);
  border-radius: 5px;
}

/* //////// Car BTN ///////// */

.Cars-card-Btn {
  /* border: 1px solid red; */
  display: flex;
  justify-content: center;
  align-items: center;
}

.Cars-card-Btn button {
  border: none;
  padding: 8px 20px;
  border-radius: 5px;
}

@media (max-width: 770px) {
  .Cars-Card-con {
    width: 100%;
    height: auto;
  }
}

@media (max-width: 768px) {
  .Cars-Card-Main-con {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
  }
}


/* =========== Projection section3 ======= */
.project-Section-03 {
  padding: 0px 0px 5vh 0px;
}
.project-Section-03 .items .item-img {
  overflow: hidden;
  position: relative;
  height: 440px;
}

.project-Section-03 .items .item-img img{
  height: 100%;
  width: 100%;

}

.project-Section-03 .items .item-img:after,
.project-Section-03 .items .item-img:before {
  content: '';
  position: absolute;
  background: #fff;
  z-index: 2;
  -webkit-transition: all .4s;
  -o-transition: all .4s;
  transition: all .4s;
}

.project-Section-03 .items .item-img>a {
  display: block;
}

.project-Section-03 .items .item-img-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(38, 38, 40, 0.5);
  text-align: center;
  -webkit-transition: all .5s;
  -o-transition: all .5s;
  transition: all .5s;
  opacity: 0;
  visibility: hidden;
}


.project-Section-03 .items .item-img:after {
  width: 50px;
  height: 1px;
  top: 50%;
  left: -100px;
}

.project-Section-03 .items .item-img:after,
.project-Section-03 .items .item-img:before {
  content: '';
  position: absolute;
  background: #fff;
  z-index: 2;
  -webkit-transition: all .4s;
  -o-transition: all .4s;
  transition: all .4s;
}

.project-Section-03 .items .info {
  background: #fff;
  position: absolute;
  bottom: 0;
  left: 15px;
  width: 100%;
  padding: 15px;
  -webkit-transform: translateX(-30px) translateY(30px) skew(10deg);
  -ms-transform: translateX(-30px) translateY(30px) skew(10deg);
  transform: translateX(-30px) translateY(30px) skew(10deg);
  opacity: 0;
  -webkit-transition: all .3s;
  -o-transition: all .3s;
  transition: all .3s;
}


.project-Section-03 .items .item-img:hover:before {
  top: 50%;
  -webkit-transform: translateY(-25px);
  -ms-transform: translateY(-25px);
  transform: translateY(-25px);
}

.project-Section-03 .items:hover:before {
  top: 50%;
  -webkit-transform: translateY(-25px);
  -ms-transform: translateY(-25px);
  transform: translateY(-25px);
}

.project-Section-03 .items:hover .info {
  opacity: 1;
  -webkit-transform: translate(0) skew(0);
  -ms-transform: translate(0) skew(0);
  transform: translate(0) skew(0);
  -webkit-transition: all .4s;
  -o-transition: all .4s;
  transition: all .4s;
}

.project-Section-03 .valign {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.project-Section-03 .items .item-img:hover .item-img-overlay {
  opacity: 1;
  visibility: visible;
}

.project-Section-03 .items .item-img:before {
  height: 50px;
  width: 1px;
  left: 50%;
  top: -100px;
}

.project-Section-03 .items .item-img:after {
  width: 50px;
  height: 1px;
  top: 50%;
  left: -100px;
}

.project-Section-03 .items .item-img:hover:before {
  top: 50%;
  transform: translateY(-25px);
}

.project-Section-03 .items .item-img:hover:after {
  left: 50%;
  -webkit-transform: translateX(-25px);
  -ms-transform: translateX(-25px);
  transform: translateX(-25px);
}

@media(max-width:430px) {
  .project-Section-1 {
    height: 50vh;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
}

.project-Section-Banner {
  height: 35vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.project-banner-text h1 {
  font-family: 'Playfair Display', serif;
  font-size: 26px;
  font-weight: 600;
  margin: 0px 0px 0px 0px;
}

}
/* ============ Banner Section =========== */
.career-Section-1 {
    height: 80vh;
    background: linear-gradient(90deg, rgba(0, 0, 0, .3), rgba(0, 0, 0, .4)), url("../Img/gallery/img16.jpg");
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
}

.career-Section-Banner{
    height: 80vh;
    display: flex;
    justify-content: center;
    align-items: center;
}

.career-banner-text h1 {
    font-family: 'Playfair Display', serif;
    font-size: 40px;
    font-weight: 600;
    margin: 0px 0px 20px 0px;
    letter-spacing: 2px;
    color: white;
}

.career-banner-text-page {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
}


.career-banner-text-page h2 {
    font-size: 17px;
    color: #b19777;
    margin: 0px;
}

.career-banner-text-page a {
    text-decoration: none;
    font-size: 17px;
    color: #b19777;
}

/* //////////////////////////////////////// section 6 ////////////////////////////////////// */

.About-Section6 {
    /* border: 1px solid red; */
    padding-bottom: 10vh;
}

.About-Section6-Heading {
    padding-top: 10vh;
    padding-bottom: 10vh;
}

.About-Section6-Heading h1 {
    font-family: 'Playfair Display', serif;
    letter-spacing: 2px;
    font-size: 40px;
    color: var(---Color01);
}

.About-Section6-Heading h2 {
    font-family: 'Playfair Display', serif;
    letter-spacing: 2px;
    font-size: 40px;
    color: black;
}

.AboutUs-Founder {
    /* border: 1px solid blue; */
}

.AboutUs-Founder-Image-con {
    /* border: 1px solid black; */
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
}


.AboutUs-Founder-Image {
    /* border: 1px solid peru; */
    position: relative;
    width: 50vh;
    height: 50vh;
    box-shadow: 0 0 5px var(---Color01);
}

.AboutUs-Founder-Image img {
    height: 100%;
    width: 100%;
}



.AboutUs-Founder-Image:before {
    content: "";
    position: absolute;
    top: -30px;
    left: -30px;
    width: 100%;
    height: 100%;
    border: 10px solid var(---Color01);
    z-index: -1;
}

.Founder-Details {
    /* border: 1px solid black; */
}

.Founder-Info {
    /* border: 1px solid blue; */
    margin: 10px;
}

.Founder-Info p {
    text-align: left;
    font-family: 'Alegreya', serif;
    font-size: 15px;
    text-align: left;
}

/* /////////////////////////////////////////////////////////////////////////////////////////////// */


.Career-Section-3 {
    /* border: 1px solid red; */
}

.Career-Section3-map {
    /* border: 1px solid blue; */
    height: 60vh;
}


/* ///////////////////// Add //////////////////////////// */

/*** 

====================================================================
	Project Form Section
====================================================================

***/

.project-form-section {
    position: relative;
    padding: 60px 0px 110px;
}

.project-form-section .content-column {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
}

.project-form-section .content-column .inner-column {
    position: relative;
    padding-left: 65px;
    padding-right: 75px;
    margin-bottom: 40px;
}

.project-form-section .content-column ul {
    position: relative;
}

.project-form-section .content-column ul li {
    position: relative;
    margin-bottom: 8px;
    color: #222222;
    font-size: 14px;
}

.project-form-section .content-column ul li span {
    position: relative;

}


/*** 

====================================================================
	Project Form
====================================================================

 ***/

.project-form {
    position: relative;
}

.project-form .form-group {
    position: relative;
    margin-bottom: 10px;
}

.project-form .form-group input[type="text"],
.project-form .form-group input[type="tel"],
.project-form .form-group input[type="email"],
.project-form .form-group select,
.project-form .form-group textarea {
    position: relative;
    display: block;
    width: 100%;
    line-height: 28px;
    padding: 10px 25px;
    color: #222222;
    height: 55px;
    font-size: 15px;
    background: #f4f4f4;
    -webkit-transition: all 300ms ease;
    -ms-transition: all 300ms ease;
    -o-transition: all 300ms ease;
    -moz-transition: all 300ms ease;
    transition: all 300ms ease;
    border: none;
}

.project-form .form-group input[type="text"]:focus,
.project-form .form-group input[type="tel"]:focus,
.project-form .form-group input[type="email"]:focus,
.project-form .form-group textarea:focus {}

.project-form .form-group textarea::-webkit-input-placeholder,
.project-form .form-group input::-webkit-input-placeholder {
    color: #888888;
}

.project-form .form-group textarea {
    height: 130px;
    resize: none;
}

.project-form button {
    margin-top: 20px;
    padding: 10px 20px;
    border: none;
    border-radius: 10px;
}

.inner-title h2{
    text-align: left;
}
.Hiring-con {
    margin: 20px 0px 0px 0px;
}

.inner-title .text {
    text-align: left;
}

.Hiring-con h3 {
    font-weight: 500;
    font-size: 25px;
    font-family: var(---Font02);
    letter-spacing: 2px;
    text-align: left;
}

.Hiring-con p {
    color: #666666;
    font-weight: 500;
    line-height: 25px;
    font-size: 14px;
    text-align: left;
}


@media(max-width:992px) {
    .About-Section6-Heading {
        padding-top: 5vh;
        padding-bottom: 5vh;
    }
}

@media(max-width:768px){
    .project-form-section .content-column .inner-column {
        position: relative;
        padding-left: 30px;
        padding-right: 30px;
        margin-bottom: 40px;
    }
}
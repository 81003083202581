html,
body {
    overflow-x: hidden;
    margin: 0;
    padding: 0;
}

section {
    padding: 70px 0px;
}

/* 
.Section-Resort-Info-Heade .nav-bar-component {
} */



.nav-bar-component {
    /* position: absolute; */
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 99;
    /* background: linear-gradient(90deg, rgba(0, 0, 0, .9), rgba(0, 0, 0, .9)); */
    z-index: 10000;
}
.main_nav
    {
      flex-grow:0;
      justify-content: center !important;
    }

.NavBar .container {
    border-radius: 0 0 5px 5px;
    background-color: rgba(255, 255, 255, 0.9);
    backdrop-filter: blur(10px)
}

.navbar-brand {
    border-right: 1px solid rgba(255, 255, 255, 0.9);
    padding: 0px 20px 0px 0px;
}


.NavSection {
    /* background-color: whitesmoke; */
    /* border-bottom: 1px solid rgb(255, 255, 255); */
}

/* TO MID ELEMENT CENTER  */

.justify-content-start {
    justify-content: center !important;
}

.navbar-toggler {
    background-color: black;
}

/* ///////////////////////////// */
.NavBar {
    /* border: 1px solid blue; */
    border-radius: 4px;
    padding: 0px;
}

.NavBar-bg {
    /* background-color: rgb(22, 176, 248); */
    /* position: fixed; */
    top: 0;
    left: 0;
    right: 0;
    z-index: 10;
}

.nav-link a {
    padding-left: 0px;
    padding-right: 0px;
    text-decoration: none;
    color: black;
    font-weight: bold;
    font-size: 16px;
    font-family: var(---Font03);
}

.navbar-dark .navbar-nav .nav-link {
    text-align: left;
    margin: 0px 5px;
    text-transform: uppercase;
    font-size: 15px;
    letter-spacing: 1px;
    color: black;
}


.Social-media-icons {
    text-align: left;
}

@media(max-width:992px) {
    .Social-media-icons {
        display: none;
    }
}

.nav-link a:hover {
    color: var(---Color01);
}

.dropdown-menu {
    height: 40vh;
    overflow-y: scroll;
}

.dropdown-menu a {
    text-decoration: none;
    color: black;
}

/*
 *  STYLE 2
 */

.dropdown-menu::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    border-radius: 10px;
    background-color: #F5F5F5;
}

.dropdown-menu::-webkit-scrollbar {
    width: 8px;
    background-color: #F5F5F5;
}

.dropdown-menu::-webkit-scrollbar-thumb {
    border-radius: 10px;
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, .3);
    background-color: #8bbc4d;
}

#collasible-nav-dropdown {
    margin: 2px 15px 0px 0px;
    font-size: 13px;
}


/* ////////////////////////////////////////// NavBar-icons ///////////////////////////////////////////////// */

.Social-media-icons svg {
    color: black;
    font-size: 20px;
}

.Social-media-icons a:hover svg {
    color: var(---Color01);
}

.Social-media-icons a {
    margin-left: 8px;
    margin-right: 8px;
}

.NavLogo {
    width: 15vh;
    height: 9vh;
}

.NavLogo img {
    height: 100%;
    width: 100%;
    transform: scale(1.4, 1.0);
}


.dorpdown {
    position: absolute;
    background: #fff;
    color: #111;
    border-radius: 5px;
    width: 200px;
    top: 100px;
    visibility: hidden;
    opacity: 0;
    z-index: -10;
    transition: 0.5s ease-in-out;
}

.dropdown_list {
    display: flex;
    justify-content: center;
    align-items: start;
    flex-direction: column;
}

.dropdown_list a {
    color: #111;
    font-size: 1rem;
    text-transform: capitalize;
    padding: 5px 10px;
    text-decoration: none;
}

.dropdown_list a:hover {
    color: var(---primaryColor);
}

.dropdownShow {
    background: unset;
    border: unset;
    text-align: left;
    /* color: white; */
    padding-left: 7px;
    padding-right: 7px;
    text-decoration: none;
    font-weight: bold;
    font-size: 16px;
    font-family: var(---Font03);
    text-transform: uppercase;
}

.dropdownShow a {
    text-decoration: none;
    color: black;
}

.dropdownShow:focus .dorpdown,
.dropdownShow:hover .dorpdown {
    top: 70px;
    visibility: visible;
    opacity: 1;
    z-index: 100;
    transition: 0.5s ease-in-out;
}

@media (max-width: 500px) {
    .dropdownShow {
        font-size: 1rem;
        padding: 7px 4px;
    }

    .dropdownShow:focus .dorpdown,
    .dropdownShow:hover .dorpdown {
        top: 180px;
        visibility: visible;
        opacity: 1;
        z-index: 100;
        transition: 0.5s ease-in-out;
    }
}



/* ////////////////////////////////////////////////////////////////////////////////////////////////////////////////// */


.header-material {
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
}

.logo {
    /* border: 1px solid red; */
}

.Email_Support {
    display: flex;
    text-align: center;
    justify-content: center;
    align-items: center;
    /* border: 1px solid green; */
}

.Call_Support {
    display: flex;
    text-align: center;
    justify-content: center;
    align-items: center;
    /* border: 1px solid saddlebrown; */
}

.Workinghr {
    display: flex;
    text-align: center;
    justify-content: center;
    align-items: center;
    /* border: 1px solid aqua;*/
}

.Appointment {
    /* border: 1px solid darkgreen; */
    display: flex;
    text-align: center;
    justify-content: center;
    align-items: center;
}

.Appointment button {
    border-style: none;
    border-radius: 4px;
    background-color: #30d2b9;
    width: 175px;
    color: white;
}

.Appointment:hover button {
    color: rgb(0, 8, 255);
}



.header-material {
    width: 100%;
    height: 5rem;
    display: grid;
    grid-template-columns: 2fr 1fr 1fr 1fr 1fr;
}


.Email-logo {
    font-size: 30px;
    padding: 10px;
    margin-bottom: 15px;
    color: #30d2b9;
}

.Email-text {
    font-size: 15px;
}

.Email-text span {
    color: rgb(84, 84, 84);
}

.Email-text h5 {
    font-size: 17px;
    color: rgb(117, 117, 117);
}

.Call-logo {
    font-size: 30px;
    padding: 10px;
    margin-bottom: 15px;
    color: #30d2b9;
}

.Call-text {
    font-size: 15px;
}

.Call-text span {
    color: rgb(84, 84, 84);
}

.Call-text h5 {
    font-size: 17px;
    color: rgb(117, 117, 117);
}

.Time-logo {
    font-size: 30px;
    padding: 10px;
    margin-bottom: 15px;
    color: #30d2b9;
}

.Time-Text {
    font-size: 15px;
}

.Time-Text span {
    color: rgb(84, 84, 84);
}

.Time-Text h5 {
    color: rgb(117, 117, 117);
    font-size: 17px;
}

@media(max-width:992px) {
    .header {
        display: none;
    }

    .navbar-collapse {
        background: linear-gradient(90deg, rgba(0, 0, 0, .3), rgba(0, 0, 0, .4));
    }

    .nav-link a {
        color: white;
    }

    .dropdownShow a {
        text-decoration: none;
        color: white;
    }

    .dorpdown {
        position: absolute;
        background: linear-gradient(90deg, rgba(0, 0, 0, 3.3), rgba(0, 0, 0, 1.4));
    }

    .navbar-nav {
        padding: 0px 0px 0px 10px;
    }

    .dropdownShow {
        font-size: 1rem;
        padding: 7px 4px;
        color: white;
    }

}

@media(max-width:430px) {

    .NavLogo {
        /* border: 1px solid red; */
        height: 10vh;
        width: 15vh;
    }
}